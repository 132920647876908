<template>
    <v-main>
        <div class="main-content mt-0">
            <Card class="card-custom br-0">
                <template #title>
                    <v-tabs
          v-model="selectedTab"
          class="main-tabs"
          background-color="var(--white)"
      >
        <v-tab to="/game-manager/providers">
            <div class="d-flex align-center">
                <span style="color: var(--text-color)">
                  {{ $t('by_providers') }} 
                </span>
                <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('game_provider_hint')"></Badge>
              </div>
        </v-tab>
        <v-tab v-if="!isAgent" to="/game-manager/blocks">
            <div class="d-flex align-center">
                <span style="color: var(--text-color)">
                  {{ $t('by_blocks') }} 
                </span>
                <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('game_block_hint')"></Badge>
              </div>
        </v-tab>
      </v-tabs>
                </template>
                <template #content>
                    <router-view>
                    </router-view>
                </template>
            </Card>
        </div>
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: "GameManager",
        data() {
            return {
                selectedTab: 0,
            }
        },
        beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'games_manager');
    });
    },
        computed:{
            ...mapGetters({
                aggregators: 'aggregators/getAggregators',
                tokenUser: 'tokenUser',
            }),
            isAgent() {
                return this.tokenUser === 'agent';
            },
        },
        async mounted() {
            if (this.isAgent) {
                return
            }
            if (!this.aggregators.length) {
            await this.getAggregators();
            }
        },
        methods: {
            async getAggregators() {
                    try {
                        await this.$store.dispatch('aggregators/getAggregators');
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: this.$t('just_error'), detail: error.message, life: 3000})
                    }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>